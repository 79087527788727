import { decorate, observable, observe, runInAction, toJS } from "mobx";
import moment from "moment";
import {
  getUser,
  getMyBalance,
  loginUser,
  mfaEmail,
  SignUpUser,
  additionalUserInfo,
  resendSignUpMail,
  verifySignUpMail,
  sendChangeMobileOTP,
  loginAsUser,
  getUserByContact,
  deleteAccountOTP,
  mfaVerification,
  resendMfaVerificationCode,
  verifyMobileForSignUp,
  verifySignupOtp,
  resendSignupOtp,
  updateGoogleSheetId,
  confirmEmail,
  faceBookLogin,
  forgotPassword,
  googleLogin,
  resetPassword,
  changePassword,
  updateUserLogo,
  updateProfileConfig,
  generateOTP,
  checkOTPRequirement,
  verifyMobileOTP,
  updateMe,
  getInvoices,
  sendMobileOTP,
  setAutoCredit,
  signUpByGoogle,
  deleteMe,
  resendOTP,
  checkFacebook,
  userCurrentRate,
  phoneLogin,
  sendUserInfo,
  getUserDocument,
  mfaEmailList,
  googleSheetList
} from "service/userService";
import { addBusinessName, deleteBusinessName } from "service/businessNameServices";
import { cookie } from "utils/cookie/index";
import { ADMIN_TOKEN, AUTH_TOKEN, AUTH_USER, AUTH_USER_BALANCE, AUTH_USER_INFO, REFRESH_TOKEN, ROLE_TOKEN, expireTime } from "constant/constant";

import { message } from "antd";

import _ from "lodash";
import { completeProfile, updateProfileSocially, verifyMyPhoneOTP, verifyUserEmail } from "service/trialUserServices";
import { getFormatedContact } from "constant/messageConstants";
import { removeDataCookies } from "constant/contacts";

class ObservableAuthStore {
  token = undefined;
  user = undefined;
  isAuthenticated = false;
  error = {};
  isOTPGenerated = false;
  isOTPRequired = undefined;
  invoices = undefined;
  newUserNotification = false;
  defaultSender = undefined;
  allSenders = [];
  invoiceDatapageSize = 10;
  invoiceDatacurrent = 1;
  invoiceDatatotalPages = 0;
  invoiceTotal = 0;
  loginEmailList = localStorage.getItem("emailOtpList") ? JSON.parse(localStorage.getItem("emailOtpList")) : [];
  invoiceDataSelectedFrom = moment("2021-04-05T05:14:27.222Z");
  invoiceDataSelectedTo = moment();
  userNotification = {
    schedule: {
      message: "New schedule message added!",
      show: false,
      type: "Schedule",
      isMms: false
    },
    bulk: {
      message: "New campaign message added!",
      show: false,
      type: "Bulk",
      isMms: false
    },
    quick: {
      message: "New message received",
      show: false,
      type: "Quick",
      isMms: false
    },
    incoming: {
      message: "New message received!",
      show: false,
      type: "Incoming",
      isMms: false
    }
  };
  response = undefined;

  balanceObj = null;
  MobileOTPResponse = undefined;
  userRate = undefined;

  loginData = undefined;

  trackedUserInfo = undefined;
  sheetList = undefined;

  setLoginData = data => {
    data ? (this.loginData = data) : (this.loginData = undefined);
  };

  dedicatedNumList = {
    sms: [],
    mms: [],
    senders: [],
    mobile: []
  };

  setData(key, data) {
    runInAction(() => {
      this[key] = data;
    });
  }

  setDedicatedNumbers = details => {
    try {
      runInAction(() => {
        if (details?.dedicatedNumber) {
          this.dedicatedNumList.sms = details?.dedicatedNumber?.sms;
          this.dedicatedNumList.mms = details?.dedicatedNumber?.mms;
        }
        if (details?.senders) {
          this.dedicatedNumList.senders = details?.senders;
        }
        // if (this.user?.mobile || this.user?.personalSenders?.length > 0) {
        //   this.dedicatedNumList.mobile = [
        //     this.user?.mobile,
        //     ...this.user?.personalSenders
        //   ].filter(x => x);
        if (this.user?.personalSenders?.length > 0) {
          this.dedicatedNumList.mobile = [...this.user?.personalSenders].filter(x => x);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @description this function is used for sending mobile OTP to the user
   *
   */
  sendMobileOTP = async params => {
    const sendMobileOTPResponse = await sendMobileOTP(params);
    try {
      runInAction(() => {
        this.MobileOTPResponse = sendMobileOTPResponse;
      });
    } catch (error) {
      this.MobileOTPResponse = error;
    } finally {
      return this.MobileOTPResponse;
    }
  };

  googleSheetList = async () => {
    const response = await googleSheetList();
    try {
      runInAction(() => {
        this.sheetList = response.data;
      });
    } catch (error) {
      this.response = error;
    } finally {
      return this.sheetList;
    }
  };

  mfaEmailList = async params => {
    const sendMobileOTPResponse = await mfaEmailList(params);
    try {
      runInAction(() => {
        this.response = sendMobileOTPResponse;
      });
    } catch (error) {
      this.response = error;
    } finally {
      return this.response;
    }
  };

  /**
   *
   * @description this function is used for verifying OTP to the mobile
   */
  verifyOTP = async params => {
    const verifyOTPResponse = await verifyMobileOTP(params);
    try {
      runInAction(() => {
        this.MobileOTPResponse = verifyOTPResponse;
        if (verifyOTPResponse.data.token) {
          this.token = verifyOTPResponse.data.token;
          this.user = verifyOTPResponse.data.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, verifyOTPResponse.data.user.mobile);
          cookie.set(AUTH_TOKEN, verifyOTPResponse.data.token);
          cookie.set(REFRESH_TOKEN, verifyOTPResponse.data.refreshToken, expireTime);
          localStorage.removeItem("emailOtpList");
          window.location.href = "/";
        }
      });
    } catch (error) {
      this.MobileOTPResponse = error;
    }
    return this.MobileOTPResponse;
  };

  /**
   *
   * @description this function is used for setting start date
   */
  setInvoiceDataPageSelectFrom = date => {
    runInAction(() => {
      this.invoiceDataSelectedFrom = date;
      this.invoiceDatapageSize = 10;
      this.invoiceDatacurrent = 1;
      this.getInvoices();
    });
  };
  /**
   *
   * @description this function is used for setting end date
   */
  setInvoiceDataPageSelectTo = date => {
    runInAction(() => {
      this.invoiceDataSelectedTo = date;
      this.invoiceDatapageSize = 10;
      this.invoiceDatacurrent = 1;
      this.getInvoices();
    });
  };
  /**
   *
   * @description this function is used for
   */

  setInvoiceDataPage = current => {
    runInAction(() => {
      this.invoiceDatacurrent = current || 1;
      this.getInvoices();
    });
  };

  setInvoiceDataPageSize = invoiceDatapageSize => {
    runInAction(() => {
      this.invoiceDatapageSize = invoiceDatapageSize;
      this.getInvoices();
    });
  };

  getInvoices = async () => {
    const params = {
      from: moment(this.invoiceDataSelectedFrom).format("YYYY/MM/DD"),
      to: moment(this.invoiceDataSelectedTo).format("YYYY/MM/DD"),
      limit: this.invoiceDatapageSize,
      page: this.invoiceDatacurrent
    };

    try {
      const getInvoicesResponse = await getInvoices(params);
      runInAction(() => {
        this.invoices = getInvoicesResponse.data.items;
        this.invoiceDatacurrent = getInvoicesResponse.data.current;
        this.invoiceTotal = getInvoicesResponse.data.total;
        this.invoiceDatatotalPages = getInvoicesResponse.data.totalPages;
      });
    } catch (error) {
      runInAction(() => {
        this.invoices = error;
      });
    } finally {
      return this.invoices;
    }
  };

  checkOTPRequirement = async params => {
    const checkOTPRequirementResponse = await checkOTPRequirement(params);
    try {
      runInAction(() => {
        this.isOTPRequired = checkOTPRequirementResponse.otpRequired;
      });
    } catch (error) {
      this.isOTPRequired = undefined;
    } finally {
      return this.isOTPRequired;
    }
  };

  generateOTP = async params => {
    const otpResponse = await generateOTP(params);
    try {
      runInAction(() => {
        this.isOTPGenerated = otpResponse.success;
        if (otpResponse.success) {
          message.success("OTP sent to your number successfully", 5);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isOTPGenerated = false;
      });
    } finally {
      return otpResponse.data;
    }
  };

  sendChangeMobileOTP = async params => {
    const otpResponse = await sendChangeMobileOTP(params);
    try {
      runInAction(() => {
        this.isOTPGenerated = otpResponse.success;
        if (otpResponse.success) {
          message.success("OTP sent to your number successfully", 5);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isOTPGenerated = false;
      });
    } finally {
      return otpResponse.data;
    }
  };

  /**
   * @returns {Promise<void>}
   */
  getUserDetails = async () => {
    // stop hitting api if already data avaialble
    const info = cookie.get(AUTH_USER_INFO);
    const userResponse = info ? cookie.get(AUTH_USER_INFO) : await getUser();
    if (!info) cookie.set(AUTH_USER_INFO, userResponse);

    try {
      runInAction(() => {
        this.user = userResponse;
        if (this.user.mobile) {
          let mobile = this.user.mobile;
          this.user.mobile = getFormatedContact(mobile);
        }
        this.defaultSender = this.user.defaultSender;
        this.user.allSenders = [
          ...this.user.senders.map(sender => {
            return {
              text: `Business: ${sender}`,
              value: sender
            };
          }),
          ...this.user.mobileSenders.map(sender => {
            return {
              text: `Dedicated: ${sender}`,
              value: sender
            };
          })
        ];

        let pSenders = this.user.personalSenders
          ? this.user.personalSenders.map(sender => {
              return {
                text: `Personal: ${sender}`,
                value: sender
              };
            })
          : [];
        this.user.allSenders = [...this.user.allSenders, ...pSenders];
        this.allSenders = this.user.allSenders;
        cookie.set(AUTH_USER, userResponse.email);
        this.isAuthenticated = true;
        this.setDedicatedNumbers(userResponse);
      });
    } catch (error) {
      runInAction(() => {
        alert(error);
        this.user = undefined;
        this.isAuthenticated = false;
      });
    }
    return this.user;
  };

  getMyBalance = async () => {
    // stop hitting api if already data avaialble
    const balance = cookie.get(AUTH_USER_BALANCE);
    const data = balance ? cookie.get(AUTH_USER_BALANCE) : await getMyBalance();
    if (!balance) cookie.set(AUTH_USER_BALANCE, data);

    try {
      runInAction(() => {
        this.balanceObj = data;
      });
    } catch (error) {
      runInAction(() => {
        alert(error);
      });
    }
    return this.balanceObj;
  };

  /**
   * Login User
   * @param loginData
   * @returns {Promise<*>}
   */
  loginUser = async loginData => {
    const response = await loginUser(loginData);
    try {
      runInAction(() => {
        this.response = { response, loginData };
        this.token = response.data.token;
        cookie.set(AUTH_TOKEN, response.data.token);
        this.loginEmailList = response?.data?.mfaEmailList ? response?.data?.mfaEmailList : [];
        localStorage.setItem("emailOtpList", JSON.stringify(this.loginEmailList));
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  /**
   * MFA Email List
   * @returns {Promise<*>}
   */
  mfaEmail = async loginData => {
    const response = await mfaEmail(loginData);
    try {
      runInAction(() => {
        if (response?.status) {
          // this.response = { response, loginData };
          this.response = response?.data;
          this.token = response?.data?.token;
          cookie.set(AUTH_TOKEN, response.data.token);
          this.loginEmailList = response?.data?.mfaEmailList ? response?.data?.mfaEmailList : [];
          localStorage.setItem("emailOtpList", JSON.stringify(this.loginEmailList));
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  signUpUser = async signUpData => {
    const signUpResponse = await SignUpUser(signUpData);
    try {
      runInAction(() => {
        this.user = signUpResponse.data.user;
        if (signUpResponse.data.user.active) {
          this.token = signUpResponse.data.token;
          this.isAuthenticated = true;
          cookie.set(AUTH_TOKEN, signUpResponse.data.token);
          cookie.set(AUTH_USER, signUpResponse.data.user.email);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    } finally {
      return signUpResponse;
    }
  };

  additionalUserInfo = async data => {
    const response = await additionalUserInfo(data);
    try {
      runInAction(() => {
        if (response.token) {
          message.success("Account Created Successfully");
          this.token = response.token;
          this.user = response.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, response.user.email);
          cookie.set(AUTH_TOKEN, response.token);
          cookie.set(REFRESH_TOKEN, response.refreshToken, expireTime);
          window.location.href = "/onboarding";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    } finally {
      return response;
    }
  };

  resendSignUpMail = async ({ email }) => {
    const response = await resendSignUpMail({ email });
    return response;
  };
  verifySignUpMail = async ({ email, code }) => {
    const response = await verifySignUpMail({ email, code });
    return response;
  };

  faceBookLogin = async signUpData => {
    const facebookResponse = await faceBookLogin(signUpData);
    try {
      runInAction(() => {
        this.token = facebookResponse.token;
        this.isAuthenticated = true;
        cookie.set(AUTH_TOKEN, facebookResponse.token);
        cookie.set(AUTH_USER, facebookResponse.user.email);
        window.location.href = "/";
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    }
  };

  googleLogin = async signUpData => {
    const googleResponse = await googleLogin(signUpData);
    try {
      runInAction(() => {
        this.token = googleResponse.token;
        this.isAuthenticated = true;
        cookie.set(AUTH_TOKEN, googleResponse.token);
        cookie.set(AUTH_USER, googleResponse.user.email);
        window.location.href = "/";
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    }
  };

  getUserByContact = async contact => {
    const response = await getUserByContact(contact);
    try {
      runInAction(() => {
        this.user = response.data;
        this.token = response.data.token;
        cookie.set(AUTH_TOKEN, response.data.token);
      });
    } catch (error) {
      runInAction(() => {
        this.user = undefined;
      });
    }
    return response;
  };

  forgotPassword = async data => {
    return await forgotPassword(data);
  };

  changePassword = async data => {
    return await changePassword(data);
  };

  resetPassword = async signUpData => {
    const resetResponse = await resetPassword(signUpData);
    try {
      runInAction(() => {
        this.token = resetResponse.token;
        this.isAuthenticated = true;
        cookie.set(AUTH_TOKEN, resetResponse.token);
        cookie.set(AUTH_USER, resetResponse.user.email);
        cookie.set(REFRESH_TOKEN, resetResponse.refreshToken, expireTime);
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    }
    return resetResponse;
  };

  confirmUser = async confirmData => {
    try {
      const resetResponse = await confirmEmail(confirmData);
      runInAction(() => {
        this.token = resetResponse.token;
        this.isAuthenticated = true;
        cookie.set(AUTH_TOKEN, resetResponse.token);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
        this.error = error;
      });
    }
    return { data: this.token, error: this.error.data };
  };

  logout = async () => {
    try {
      runInAction(() => {
        this.token = undefined;
        this.isAuthenticated = false;
        this.user = undefined;
        cookie.erase(AUTH_TOKEN);
        cookie.erase(REFRESH_TOKEN);
        cookie.erase(AUTH_USER);
        cookie.erase(ROLE_TOKEN);
        cookie.erase(ADMIN_TOKEN);
        cookie.erase(AUTH_USER_INFO);
        cookie.erase(AUTH_USER_BALANCE);
        // localStorage.clear();
        window.location.href = "/";
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    }
  };

  //   cookie.erase(AUTH_TOKEN);
  //   cookie.erase(REFRESH_TOKEN);
  //   cookie.erase(AUTH_USER);
  //   window.location.href = "/login";
  // };

  updateUserLogo = async params => {
    try {
      const updateUserLogoResponse = await updateUserLogo(params);
      runInAction(() => {
        if (updateUserLogoResponse.success) {
          message.success("Your logo has been updated!", 1).then(() => {
            // window.location.reload(true);
          });
        }
      });
    } catch (error) {
      this.error = error;
    } finally {
      return this.user;
    }
  };

  updateProfileConfig = async params => {
    const updateProfileConfigResponse = await updateProfileConfig(params);
    if (!params?.isNotAutoCredit) {
      await this.setAutoCredit(params);
    }
    try {
      runInAction(() => {
        this.user = updateProfileConfigResponse.user;
      });
    } catch (error) {
      runInAction(() => {
        this.user = error;
      });
    } finally {
      return this.user;
    }
  };

  updateUser = async params => {
    const update_ME = await updateMe(params);
    try {
      runInAction(() => {
        this.user = update_ME.user;
      });
    } catch (error) {
      runInAction(() => {
        this.user = error;
      });
    } finally {
      return this.user;
    }
  };

  setAutoCredit = async values => {
    const autCreditResponse = await setAutoCredit(values);
    try {
      runInAction(() => {
        this.getUserDetails();
      });
    } catch (error) {
      console.log(error);
    }

    return autCreditResponse;
  };

  setnewUserNotification = (showNotification = true) => {
    try {
      runInAction(() => {
        this.newUserNotification = showNotification;
      });
    } catch (error) {
      console.log(error);
    }
    return this.newUserNotification;
  };

  setuserNotificationList = notificationObj => {
    try {
      runInAction(() => {
        if (notificationObj.status == "received") {
          this.userNotification.incoming.show = true;
          this.userNotification.incoming.isMms = notificationObj.isMMS;
        } else if (notificationObj.schedule) {
          this.userNotification.schedule.show = true;
          this.userNotification.schedule.isMms = notificationObj.isMMS;
        } else if (notificationObj.bulk) {
          this.userNotification.bulk.show = true;
          this.userNotification.bulk.isMms = notificationObj.isMMS;
        } else {
          this.userNotification.quick.show = true;
          this.userNotification.quick.isMms = notificationObj.isMMS;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  setReadNotification = async (read, click, type) => {
    try {
      runInAction(() => {
        if (read) {
          this.newUserNotification = false;
        }
        if (click) {
          this.newUserNotification = false;
          if (type == "Incoming") this.userNotification.incoming.show = false;
          else if (type == "Schedule") this.userNotification.schedule.show = false;
          else if (type == "Bulk") this.userNotification.bulk.show = false;
          else this.userNotification.quick.show = false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  socialSignup = async data => {
    const signUpResponse = await signUpByGoogle(data);
    try {
      runInAction(() => {
        this.user = signUpResponse.user;
        // message.success("Success!", 1);
        // if (signUpResponse.user.active) {
        //   this.token = signUpResponse.token;
        //   this.isAuthenticated = true;
        //   cookie.set(AUTH_TOKEN, signUpResponse.token);
        //   cookie.set(AUTH_USER, signUpResponse.user.email);
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    } finally {
      return signUpResponse;
    }
  };

  loginBySocial = async loginData => {
    const response = await signUpByGoogle(loginData);
    try {
      runInAction(() => {
        this.response = { response, loginData };
        if (response.data?.token) {
          this.token = response.data?.token;
          cookie.set(AUTH_TOKEN, response.data?.token);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // Login As User
  loginAsUser = async user => {
    const response = await loginAsUser(user);
    try {
      runInAction(() => {
        removeDataCookies();
        this.token = response.token;
        this.isAuthenticated = true;
        cookie.set(AUTH_USER, response.user.email);
        cookie.set(AUTH_TOKEN, response.token);
        cookie.set(REFRESH_TOKEN, response.refreshToken, expireTime);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      });
    } catch (error) {
      runInAction(() => {
        this.token = this.token;
        console.log(error);
      });
    }
  };
  resendOTP = async values => {
    const response = await resendOTP(values);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  checkFacebook = async values => {
    const response = await checkFacebook(values);
    return response;
  };

  addBusinessName = async values => {
    const response = await addBusinessName(values);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.user.senders = response.data.senders;
          this.setDedicatedNumbers(response.data);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
        this.user.senders = this.user.senders;
      });
    }
    return this.response;
  };

  deleteBusinessName = async values => {
    const response = await deleteBusinessName(values);
    try {
      runInAction(() => {
        this.response = response;
        this.user.senders = response.data.senders;
        this.user.allSenders = this.user.allSenders.filter(sender => sender.value !== values.buisness);
        this.allSenders = this.user.allSenders;
        this.setDedicatedNumbers(response.data);
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  deleteAccountOTP = async message => {
    const response = await deleteAccountOTP(message);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  deleteMe = async otp => {
    const response = await deleteMe(otp);
    try {
      runInAction(() => {
        if (response.status) {
          this.token = undefined;
          this.isAuthenticated = false;
          this.user = undefined;
          cookie.erase(AUTH_TOKEN);
          cookie.erase(AUTH_USER);
          cookie.erase(REFRESH_TOKEN);
          window.location.href = "/";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  mfaVerification = async otp => {
    const response = await mfaVerification(otp);
    const loginResponse = response.data;
    try {
      runInAction(() => {
        if (!_.isEmpty(loginResponse) && loginResponse.user && loginResponse.user.active) {
          this.token = loginResponse.token;
          this.user = loginResponse.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, loginResponse.user.email);
          cookie.set(AUTH_TOKEN, loginResponse.token);
          cookie.set(REFRESH_TOKEN, loginResponse.refreshToken, expireTime);
          localStorage.removeItem("emailOtpList");
          if (this.user.role == "superAdmin") {
            window.location.href = "/admin/dashboard";
          } else {
            // window.location.href = "/quick-actions";
            window.location.href = localStorage.getItem("last-path")?.includes("auth") ? localStorage.getItem("last-path") : "/waiting/quick-actions";
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.token = undefined;
      });
    }
    return response;
  };

  resendMfaVerificationCode = async email => {
    const response = await resendMfaVerificationCode(email);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // resend signup otp
  resendSignupOtp = async contact => {
    const response = await resendSignupOtp(contact);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  userCurrentRate = async () => {
    const response = await userCurrentRate();
    try {
      runInAction(() => {
        this.userRate = response;
      });
    } catch (error) {
      runInAction(() => {
        this.userRate = error;
      });
    }
    return this.userRate;
  };

  // new functions

  loginUserWithPhone = async params => {
    const response = await phoneLogin(params);
    try {
      runInAction(() => {
        this.response = { response, params };
        this.token = response.data.token;
        cookie.set(AUTH_TOKEN, response.data.token);
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // completing user profile by email
  completeUserProfile = async params => {
    const data = await completeProfile(params);
    const response = data.data;
    try {
      runInAction(() => {
        if (response.token) {
          this.response = { response: data, params };
          this.token = response.token;
          this.user = response.user;
          this.isAuthenticated = false;
          cookie.set(AUTH_USER, response.user.email);
          cookie.set(AUTH_TOKEN, response.token);
          cookie.set(REFRESH_TOKEN, response.refreshToken, expireTime);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return data;
  };

  // social signup to complete profile
  updateProfileSocially = async (params, userParams) => {
    const response = await updateProfileSocially(params, userParams);
    try {
      runInAction(() => {
        this.response = { response, params };
        if (response.status) {
          window.location = "/";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // verify mobile no with otp for new signuped user
  signupMobileOTP = async params => {
    const verifyOTPResponse = await verifyMobileForSignUp(params);
    try {
      runInAction(() => {
        this.MobileOTPResponse = verifyOTPResponse;
        if (verifyOTPResponse.data.token) {
          this.token = verifyOTPResponse.data.token;
          this.user = verifyOTPResponse.data.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, verifyOTPResponse.data.user.mobile);
          cookie.set(AUTH_TOKEN, verifyOTPResponse.data.token);
          cookie.set(REFRESH_TOKEN, verifyOTPResponse.data.refreshToken, expireTime);
          window.location.href = "/";
        }
      });
    } catch (error) {
      this.MobileOTPResponse = error;
    }
    return this.MobileOTPResponse;
  };

  verifySignupOtp = async params => {
    const verifyOTPResponse = await verifySignupOtp(params);
    try {
      runInAction(() => {
        this.MobileOTPResponse = verifyOTPResponse;
        if (verifyOTPResponse.data.token) {
          this.token = verifyOTPResponse.data.token;
          this.user = verifyOTPResponse.data.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, verifyOTPResponse.data.user.mobile);
          cookie.set(AUTH_TOKEN, verifyOTPResponse.data.token);
          cookie.set(REFRESH_TOKEN, verifyOTPResponse.data.refreshToken, expireTime);
          // window.location.href = "/quick-actions";
          // window.location.href = "/waiting/quick-actions";
        }
      });
    } catch (error) {
      this.MobileOTPResponse = error;
    }
    return this.MobileOTPResponse;
  };

  verifyPhoneOTP = async params => {
    const verifyOTPResponse = await verifyMyPhoneOTP(params);
    try {
      runInAction(() => {
        this.MobileOTPResponse = verifyOTPResponse;
        if (verifyOTPResponse.data.token) {
          this.token = verifyOTPResponse.data.token;
          this.user = verifyOTPResponse.data.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, verifyOTPResponse.data.user.mobile);
          cookie.set(AUTH_TOKEN, verifyOTPResponse.data.token);
          cookie.set(REFRESH_TOKEN, verifyOTPResponse.data.refreshToken, expireTime);
          localStorage.removeItem("emailOtpList");
          window.location.href = "/";
        }
      });
    } catch (error) {
      this.MobileOTPResponse = error;
    }
    return this.MobileOTPResponse;
  };

  // verify trial user email
  verifyUserEmail = async data => {
    const verifyOTPResponse = await verifyUserEmail(data);
    try {
      runInAction(() => {
        this.MobileOTPResponse = verifyOTPResponse;
        if (verifyOTPResponse.data.token) {
          this.token = verifyOTPResponse.data.token;
          this.user = verifyOTPResponse.data.user;
          this.isAuthenticated = true;
          cookie.set(AUTH_USER, verifyOTPResponse.data.user.mobile);
          cookie.set(AUTH_TOKEN, verifyOTPResponse.data.token);
          cookie.set(REFRESH_TOKEN, verifyOTPResponse.data.refreshToken, expireTime);
        }
      });
    } catch (error) {
      // this.MobileOTPResponse = error;
      console.log(error);
      verifyOTPResponse = error;
    }
    return verifyOTPResponse;
  };

  // for adding and checking google sheet
  handleGoogleSheetUrl = async data => {
    const response = await updateGoogleSheetId(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (err) {
      console.log(err);
      response = err;
    }
    return response;
  };

  // get user uploaded documents
  getUserDocument = async name => {
    const response = await getUserDocument(name);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (err) {
      this.response = err;
    }
    return this.response;
  };
}

decorate(ObservableAuthStore, {
  user: observable,
  token: observable,
  isAuthenticated: observable,
  allSenders: observable,
  error: observable,
  invoices: observable,
  isOTPGenerated: observable,
  isOTPRequired: observable,
  newUserNotification: observable,
  userNotification: observable,
  defaultSender: observable,
  balanceObj: observable,
  invoiceDatapageSize: observable,
  invoiceDatacurrent: observable,
  invoiceDatatotalPages: observable,
  invoiceTotal: observable,
  invoiceDataPageSelectTo: observable,
  invoiceDataPageSelectFrom: observable,
  userRate: observable,
  dedicatedNumList: observable,
  loginData: observable,
  loginEmailList: observable,
  sheetList: observable
});
export default new ObservableAuthStore();
